import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';

import { configPlayer, mapAssetType } from './video-utils';
import { DEFAULT_CTA } from './metadata';
import closeIcon from './media/close.svg';
import passportIcon from './media/passport.svg';
import styles from './video-player.scss';

class VideoPlayer extends Component {
    static propTypes = {
        componentId: PropTypes.string.isRequired,
        handleCTAChange: PropTypes.func.isRequired,
        CallToAction: PropTypes.func.isRequired,
        showVideo: PropTypes.bool.isRequired,
        playerClass: PropTypes.string,
        videoClose: PropTypes.func.isRequired,
        cta: PropTypes.shape({
            link: PropTypes.object,
            text: PropTypes.string,
            textPlaceholder: PropTypes.string
        }),
        settings: PropTypes.shape({
            displayCTA: PropTypes.bool.isRequired,
            player: PropTypes.shape({
                topbar: PropTypes.bool.isRequired,
                autoplay: PropTypes.bool.isRequired
            }).isRequired,
            displayVideoTitle: PropTypes.bool.isRequired,
            displayShowTitle: PropTypes.bool.isRequired,
            displayVideoMeta: PropTypes.bool.isRequired
        }),
        callsign: PropTypes.string,
        currentSite: PropTypes.object,
        currentVideo: PropTypes.object,
        isSomethingDragging: PropTypes.bool.isRequired,
        reference: PropTypes.object,
        isPillButtonAvailable: PropTypes.bool
    };

    static defaultProps = {
        cta: DEFAULT_CTA,
        playerClass: '',
        isSomethingDragging: false
    };

    getCallsign = () => {
        const { currentSite: { siteType, station } = {}, callsign } = this.props;
        if (callsign) {
            return callsign;
        }
        if (siteType === 'station' && station && station.callSign) {
            return station.callSign;
        }
        return '';
    };

    render() {
        const {
            settings: { player, displayCTA, displayVideoTitle, displayShowTitle, displayVideoMeta },
            currentVideo,
            componentId,
            handleCTAChange,
            cta,
            CallToAction,
            showVideo,
            playerClass,
            videoClose,
            isSomethingDragging,
            reference,
            isPillButtonAvailable
        } = this.props;

        if (playerClass && !showVideo) {
            return null;
        }
        if (!currentVideo) {
            return null;
        }

        const {
            showTitle,
            videoTitle,
            duration,
            date,
            rating,
            type,
            longDescription,
            id,
            isPassportVideo
        } = currentVideo;
        const videoMeta = [mapAssetType(type), duration, date, rating]
            .filter((item) => !!item)
            .join(' | ');
        const callsign = this.getCallsign();
        const config = callsign ? { ...player, callsign } : player;

        return (
            <div
                ref={reference}
                className={classnames({
                    [styles.videoPlayer]: true,
                    'video-player': true,
                    [playerClass]: true
                })}>
                {playerClass && <img className="close" src={closeIcon} onClick={videoClose} />}
                <div className="left-side">
                    <div
                        className={classnames({
                            'rsponsive-player-wrapper': true,
                            'top-bar': player.topbar,
                            'shape-placeholder-fill': true
                        })}>
                        {currentVideo.playerIframe && !isSomethingDragging && (
                            <div
                                className="player"
                                dangerouslySetInnerHTML={{
                                    __html: configPlayer(currentVideo.playerIframe, config)
                                }}
                            />
                        )}
                    </div>
                </div>
                <div className="right-side">
                    {displayShowTitle && (
                        <span className="text-placeholder show-title">{showTitle}</span>
                    )}
                    {displayVideoTitle && (
                        <span className="text-placeholder video-title">{videoTitle}</span>
                    )}
                    {displayVideoMeta && (
                        <div className="text-placeholder video-meta">
                            {isPassportVideo && (
                                <img
                                    className="passport-icon"
                                    src={passportIcon}
                                    alt="Passport icon"
                                />
                            )}
                            {videoMeta}
                        </div>
                    )}
                    <p className="text-placeholder-long double description">{longDescription}</p>
                    {displayCTA && (
                        <CallToAction
                            {...this.props}
                            componentId={componentId}
                            handleCTAChange={handleCTAChange}
                            videoId={id}
                            cta={cta}
                            isPillButtonAvailable={isPillButtonAvailable}
                        />
                    )}
                </div>
            </div>
        );
    }
}

export default VideoPlayer;
