/**
 * Base URL for console.
 * @constant
 * @type {string}
 */
export const CONSOLE_BASE_URL = '/console/';

const SITE_BASE_URL = CONSOLE_BASE_URL + 'sites/:siteId/';

export const DOCS_BASE_URL = 'https://docs.pbs.org/space/';

/**
 * Constants for route paths and parents if nested
 * @constant
 * @type {object}
 */

export const paths = {
    // GENERAL
    EMPTY_DASHBOARD: CONSOLE_BASE_URL + 'dashboard',
    ADD_SITE: CONSOLE_BASE_URL + 'sites/add',
    LOGOUT: CONSOLE_BASE_URL + 'logout',
    // SITE SPECIFIC
    SITE_DASHBOARD: SITE_BASE_URL + 'dashboard',
    PAGES: SITE_BASE_URL + 'pages',
    ADD_PAGE: SITE_BASE_URL + 'pages/add',
    EDIT_PAGE: SITE_BASE_URL + 'pages/:pageId',
    PAGE_SETTINGS: SITE_BASE_URL + 'pages/:pageId/settings',
    COMPONENT_SETTINGS: SITE_BASE_URL + 'pages/:pageId/components/:componentId',
    LAYOUT_SETTINGS: SITE_BASE_URL + 'pages/:pageId/layouts/:layoutId',
    COMPONENT_LAYOUT_SETTINGS:
        SITE_BASE_URL + 'pages/:pageId/components/:componentId/layouts/:layoutId',
    STYLE: SITE_BASE_URL + 'styles',
    STYLE_PAGE: SITE_BASE_URL + 'styles/:pageId',
    SITE_SETTINGS: SITE_BASE_URL + 'settings',
    FILES: SITE_BASE_URL + 'files',
    FILER: SITE_BASE_URL + 'filer',
    TRASH: SITE_BASE_URL + 'trash',
    REDIRECTS: SITE_BASE_URL + 'redirects'
};

//needed for creating the <<Back link in breadcrumb.jsx
export const breadcrumbs = {
    PAGES: SITE_BASE_URL + 'pages',
    SITE_DASHBOARD: SITE_BASE_URL + 'dashboard',
    EDIT_PAGE: SITE_BASE_URL + 'pages/:pageId',
    PAGE_SETTINGS: SITE_BASE_URL + 'pages/:pageId/settings',
    COMPONENT_SETTINGS: SITE_BASE_URL + 'pages/:pageId/components/:componentId',
    LAYOUT_SETTINGS: SITE_BASE_URL + 'pages/:pageId/layouts/:layoutId',
    STYLE_PAGE: SITE_BASE_URL + 'styles/:pageId'
};

export const menuItems = {
    DASHBOARD: { title: 'Dashboard', path: paths.SITE_DASHBOARD },
    PAGES: { title: 'Pages', path: paths.PAGES },
    FILES: { title: 'Files', path: paths.FILES },
    FILER: { title: 'Filer', path: paths.FILER },
    REDIRECTS: { title: 'Redirects', path: paths.REDIRECTS },
    STYLE: { title: 'Style', path: paths.STYLE },
    SITE_SETTINGS: { title: 'Site Settings', path: paths.SITE_SETTINGS }
};

export const sidebarViews = {
    MAIN_NAV: 'Main Navigation',
    PAGES: 'Pages',
    PAGE_SETTINGS: 'Page Settings',
    COMPONENT_SETTINGS: 'Component Settings'
};

export const mainViews = {
    DASHBOARD: 'Dashboard',
    EDIT_PAGE: 'Edit Page',
    BLOGGER: 'Blogger',
    STYLE: 'Style',
    SITE_SETTINGS: 'Site Settings',
    ADD_SITE: 'Add Site'
};

export const dashboardSections = {
    ADD_NEW_SITE: 'AddNewSite'
};

export const pagesMenuSections = {
    PAGE_TREE: 'PAGE_TREE',
    PAGE_SETTINGS: 'PAGE_SETTINGS'
};

export const apiStatus = {
    NOT_REQUESTED: 'NOT_REQUESTED', // Initial state, may not be used again
    PENDING: 'PENDING', // Preparatory phase, but no request yet
    IN_PROGRESS: 'IN_PROGRESS',
    OK: 'OK',
    FAILED: 'FAILED',
    CANCELLED: 'CANCELLED',
    FILER_LOAD_FILES: 'FILER_LOAD_FILES'
};

export const pageSection = {
    HEADER: 'header',
    CONTENT: 'contentVersion',
    FOOTER: 'footer',
    RAIL: 'rail'
};

export const sectionType = {
    HEADER: 'header',
    CONTENT: 'content',
    FOOTER: 'footer',
    ERROR: 'error',
    SEARCH: 'search',
    COMPONENT: 'component',
    RAIL: 'rail',
    POPUP: 'popup'
};

export const DEFAULT_LINK = {
    externalHref: '',
    fileId: null,
    pageId: null,
    target: null,
    enabled: false
};

export const EMPTY_EDIT_DATA = {
    layouts: {},
    columns: {},
    components: {},
    section: { layouts: [] }
};

export const COLUMN_TYPES = [
    { colSizes: [12], colTitle: '1 Column' },
    { colSizes: [6, 6], colTitle: '2 Column' },
    { colSizes: [8, 4], colTitle: '2/3 - 1/3 Column' },
    { colSizes: [4, 4, 4], colTitle: '3 Column' },
    { colSizes: [3, 3, 3, 3], colTitle: '4 Column' }
];

export const SITE_TYPE = {
    STATION: { key: 'station', label: 'Station' },
    PRODUCER: { key: 'producer', label: 'Producer' },
    INTERNAL_PBS: { key: 'internal_pbs', label: 'Internal PBS' }
};

export const RAIL_TYPE = {
    LEFT: { key: 'left', label: 'Left' },
    RIGHT: { key: 'right', label: 'Right' }
};

export const SEO_LIMITS = {
    titleLength: 100,
    descriptionLength: 160,
    tagsLength: 255
};

export const ALL_COLUMNS = new Set(
    COLUMN_TYPES.reduce((acc, col) => {
        return [...acc, ...col.colSizes];
    }, [])
);

export const ALL_SECTIONS = new Set(Object.keys(sectionType).map((key) => sectionType[key]));

export const ALL_SITE_TYPES = new Set(Object.keys(SITE_TYPE).map((type) => SITE_TYPE[type].key));

export const PERMISSION_TYPES = {
    add_user: 'add_user',
    change_user: 'change_user',
    delete_user: 'delete_user',
    add_page: 'add_page',
    change_page: 'change_page',
    delete_page: 'delete_page',
    move_page: 'move_page',
    copy_page: 'copy_page',
    add_contentversion: 'add_contentversion',
    change_contentversion: 'change_contentversion',
    delete_contentversion: 'delete_contentversion',
    can_publish_contentversion: 'can_publish_contentversion',
    can_unpublish_contentversion: 'can_unpublish_contentversion',
    add_site: 'add_site',
    change_site: 'change_site',
    add_sitesection: 'add_sitesection',
    change_sitesection: 'change_sitesection',
    delete_sitesection: 'delete_sitesection',
    change_siteVRM: 'change_siteVRM'
};

export const SAMPLE_PAGE_ID = 'sample-page';
export const ADD_PAGE_ID = 'add-page';

export const SPECIAL_PAGE = {
    ERROR_400: 'error_400',
    ERROR_404: 'error_404',
    ERROR_500: 'error_500',
    SEARCH: 'search',
    POPUP: 'popup',
    BLOGS: 'blogs'
};

export const PAGE_TREE_TYPE = {
    FAKE_ROOT: 'fake_root',
    SPECIAL_ROOT: 'special_root',
    BLOGS_ROOT: 'blogs_root',
    POPUP_COLLECTION_ROOT: 'popup_collection_root'
};

export const SITE_FILTERS = {
    ALL_SITES: {
        id: 'ALL_SITES',
        name: 'ALL SITES'
    }
};

export const BENTOMATIC_VAR_TABS = {
    NEW: {
        name: 'NEW',
        label: 'New Variable'
    },
    EXISTING: {
        name: 'EXISTING',
        label: 'Existing Variable'
    }
};

export const operationTypes = {
    PAGE_MOVE: 'page_move',
    PAGE_COPY: 'page_copy',
    PAGE_ADD: 'page_add',
    CUT_LAYOUT: 'CUT_LAYOUT',
    COPY_LAYOUT: 'COPY_LAYOUT',
    CUT_COMPONENT: 'CUT_COMPONENT',
    COPY_COMPONENT: 'COPY_COMPONENT'
};

export const TABS_COMPONENT_ASSETS = {
    SITE_FILES: {
        name: 'SITE_FILES',
        label: 'Site Files'
    },
    MEDIA_MANAGER: {
        name: 'MEDIA_MANAGER',
        label: 'Media Manager'
    }
};

export const APP_NAME = 'Bento 3';

export const VRM_SITE_TYPE = {
    PROXIED: { key: 'proxied', label: 'Proxied' },
    HOSTED: { key: 'hosted', label: 'Hosted' }
};

export const ANALYZE_PERFORMANCE_URL = 'https://pagespeed.web.dev/report?url=';

export const undoActions = {
    ADD_LAYOUT: 'Add Layout',
    PASTE_CUT_LAYOUT: 'Paste Cut Layout',
    PASTE_COPIED_LAYOUT: 'Paste Copied Layout',
    REMOVE_LAYOUT: 'Remove Layout',
    MOVE_LAYOUT: 'Move Layout',
    CHANGE_LAYOUT_TYPE: 'Change Layout Type',
    CHANGE_LAYOUT_SETTINGS: 'Change Layout Settings',
    CHANGE_LAYOUT_SETTINGS_BACKGRUOND: 'Change Layout Settings Backgruond Color',
    CHANGE_LAYOUT_SETTINGS_NAME: 'Change Layout Settings Name',
    CHANGE_LAYOUT_SETTINGS_SHOWBACKGROUNDCOLOR: 'Change Layout Settings Show Background Color',
    CHANGE_LAYOUT_SETTINGS_SHOWBACKGROUNDIMAGE: 'Change Layout Settings Show Background Image',
    CHANGE_LAYOUT_SETTINGS_BACKGROUND_IMAGE: 'Change Layout Settings Background Image',
    CHANGE_LAYOUT_SETTINGS_BACKGROUND_IMAGE_MM: 'Change Layout Settings Background Image Mm',
    CHANGE_LAYOUT_SETTINGS_PADDING: 'Change Layout Settings Padding',
    CHANGE_LAYOUT_SETTINGS_ISFULLWIDTH: 'Change Layout Settings Full Width',
    CHANGE_LAYOUT_SETTINGS_ISFULLHEIGHT: 'Change Layout Settings Full Height',
    CHANGE_LAYOUT_SETTINGS_ISCONTENTVERTICALLYCENTERED:
        'Change Layout Settings Vertically Centered',
    TOGGLE_LAYOUT_DELETE: 'Toggle Layout Delete',
    ADD_COLUMN: 'Add Column',
    REMOVE_COLUMN: 'Remove Column',
    CHANGE_COLUMN_SIZE: 'Change Column Size',
    ADD_COMPONENT: 'Add Component',
    REMOVE_COMPONENT: 'Remove Component',
    REMOVE_COMPONENT_ERROR_BOUNDARY: 'Remove Component Error Boundary',
    MOVE_COMPONENT: 'Move Component',
    CHANGE_COMPONENT_SETTINGS: 'Change Component Settings',
    CHANGE_COMPONENT_DATA: 'Change Component Data',
    CHANGE_BENTOMATIC_DATA: 'Change Bentomatic Data',
    CHANGE_COMPONENT_POSITION: 'Change Component Position',
    PASTE_CUT_COMPONENT: 'Paste Cut Component',
    PASTE_COPIED_COMPONENT: 'Paste Copied Component',
    SET_COLUMN_DISPLAY_SIZE: 'Set Column Display Size',
    IMAGE_COMPONENT_CHANGE_ASSET: 'Image Component Change Asset',
    IMAGE_COMPONENT_CHANGE_CAPTION: 'Image Component Change Caption',
    IMAGE_COMPONENT_CHANGE_ALT_TEXT: 'Image Component Change Alt Text',
    IMAGE_COMPONENT_CHANGE_CREDIT: 'Image Component Change Credit',
    IMAGE_COMPONENT_CHANGE_LINK: 'Image Component Change Link',
    IMAGE_COMPONENT_CHANGE_SETTING: 'Image Component Change Setting',
    VIDEO_COMPONENT_FIRST_ITEM_UPDATE: 'Video Component First Item Update',
    VIDEO_COMPONENT_PLAYLIST_HEIGHT: 'Video Component Playlist Height',
    VIDEO_COMPONENT_DEFAULT: 'Video Component Default',
    VIDEO_COMPONENT_DETAILS: 'Video Component Details',
    VIDEO_COMPONENT_SET_PLAYLIST: 'Video Component Set Playlist',
    VIDEO_COMPONENT_SET_NEW_PLAYLIST: 'Video Component Set New Playlist',
    VIDEO_COMPONENT_SORT: 'Video Component Sort',
    VIDEO_COMPONENT_UPDATE_PLAYLIST_ITEM: 'Video Component Update Playlist Item',
    VIDEO_COMPONENT_REMOVE: 'Video Component Remove',
    VIDEO_COMPONENT_SETTINGS_PLAYLIST_HEIGHT: 'Video Component Settings Playlist Height',
    VIDEO_LIST_COMPONENT_SETTINGS_CHANGE_SHOW: 'Video List Component Settings Change Show',
    VIDEO_LIST_COMPONENT_SETTINGS_SET_ITEMS_PER_PAGE:
        'Video List Component Settings Set Items Per Page',
    VIDEO_LIST_COMPONENT_SETTINGS_TOOGLE: 'Video List Component Settings Toogle',
    VIDEO_LIST_COMPONENT_SETTINGS_CHANGE: 'Video List Component Settings Change',
    VIDEO_LIST_COMPONENT_SETTINGS_GEO_AVAILABILITY: 'Video List Component Geo locations Change',
    VIDEO_LIST_COMPONENT_SETTINGS_ORDER: 'Video List Component Settings Order',
    AUDIO_PLAYER_SET_IMG_URL: 'Audio Player Set Img Url',
    AUDIO_PLAYER_SET_IMG_ALT: 'Audio Player Set Img Alt',
    AUDIO_PLAYER_SET_IMG: 'Audio Player Set Img',
    AUDIO_PLAYER_SET_STATION: 'Audio Player Set Station',
    AUDIO_PLAYER_SET_TITLE: 'Audio Player Set Title',
    AUDIO_PLAYER_SETTINGS_URL: 'Audio Player Settings Url',
    AUDIO_PLAYER_SETTINGS_SHOW_STATION: 'Audio Player Settings Show Station',
    AUDIO_PLAYER_SETTINGS_SHOW_IMAGE: 'Audio Player Settings Show Image',
    AUDIO_PLAYER_SETTINGS_ALT_TEXT: 'Audio Player Settings Alt Text',
    CODE_COMPONENT_CHANGE_CONTENT: 'Code Component Change Content',
    SOCIAL_ADD_NEW_ICON: 'Social Add New Icon',
    SOCIAL_CHANGE_LINK: 'Social Change Link',
    SOCIAL_DELETE_ICON: 'Social Delete Icon',
    SOCIAL_TEXT: 'Social Text',
    SOCIAL_SORT: 'Social Sort',
    SOCIAL_SETTINGS_COLOR_THEME: 'Social Settings Color Theme',
    SOCIAL_SETTINGS_SHOW_ICON_BORDER: 'Social Settings Show Icon Border',
    SOCIAL_SETTINGS_SHOW_TEXT: 'Social Settings Show Text',
    SOCIAL_SETTINGS_ALIGN: 'Social Settings Align',
    FACEBOOK_LIKE_TITLE: 'Facebook Like Title',
    FACEBOOK_LIKE_SETTINGS_RADIO: 'Facebook Like Settings Radio',
    FACEBOOK_LIKE_SETTINGS_TOGGLE: 'Facebook Like Settings Toggle',
    FACEBOOK_LIKE_SETTINGS_PAGE_NAME: 'Facebook Like Settings Page Name',
    FACEBOOK_LIKE_SETTINGS_SELECT_LAYOUT: 'Facebook Like Settings Select Layout',
    TWITTER_FOLLOW_SET_DATA: 'X Follow Set Data',
    TWITTER_FOLLOW_SETTINGS_RADIO: 'X Follow Settings Radio',
    TWITTER_FOLLOW_SETTINGS_TOGGLE: 'X Follow Settings Toggle',
    TWITTER_FOLLOW_SETTINGS_SCREEN_NAME: 'X Follow Settings Screen Name',
    CODE_COMPONENT_CHANGE_LABEL: 'Code Component Change Label',
    CODE_COMPONENT_CHANGE_ENABLE: 'Code Component Change Enable',
    TEXT_COMPONENT_CHANGE_CONTENT: 'Text Component Change Content',
    TEXT_COMPONENT_CHANGE_SETTINGS_BACKGROUND: 'Text Component Change Settings Background',
    TEXT_COMPONENT_CHANGE_SETTINGS_PADDING: 'Text Component Change Settings Padding',
    TEXT_COMPONENT_CHANGE_SETTINGS_MAX_WIDTH: 'Text Component Change Settings Max Width',
    LINKS_COMPONENT_NEW_ITEM: 'Links Component New Item',
    LINKS_COMPONENT_CHANGE: 'Links Component Change',
    LINKS_COMPONENT_DELETE: 'Links Component Delete',
    LINKS_COMPONENT_SORT: 'Links Component Sort',
    LINKS_COMPONENT_SET_TITLE: 'Links Component Set Title',
    SIMPLE_BUTTON_COMPONENT_LINK: 'Simple Button Component Link',
    SIMPLE_BUTTON_COMPONENT_CHANGE: 'Simple Button Component Change',
    JAWS_COMPONENT_SETTINGS_SET_API: 'Jaws Component Settings Set Api',
    JAWS_WHATS_ON_SETTINGS: 'Jaws Whats On Settings',
    SHOP_SETTINGS_ORIENTATION: 'Shop Settings Orientation',
    SHOP_SETTINGS_VERTICAL_ORIENTATION: 'Shop Settings Vertical Orientation',
    SHOP_SETTINGS_SHOW_DESCRIPTION: 'Shop Settings Show Description',
    SHOP_SETTINGS_SHOW_LINKS: 'Shop Settings Show Links',
    SHOP_SETTINGS_SHOW_LOGO: 'Shop Settings Show Logo',
    SHOP_SETTINGS_ALT_TEXT: 'Shop Settings Alt Text',
    SHOP_MM_SELECTION: 'Shop Mm Selection',
    SHOP_ADD_NEW_LINK: 'Shop Add New Link',
    SHOP_CHANGE_LINK: 'Shop Change Link',
    SHOP_DELETE_LINK: 'Shop Delete Link',
    SHOP_SORT_ITEM: 'Shop Sort Item',
    SHOP_SET_IMAGE: 'Shop Set Image',
    SHOP_IMG: 'Shop Img',
    SHOP_DESCRIPTION: 'Shop Description',
    DONATE_CHANGE_LOGO: 'Donate Change Logo',
    DONATE_CHANGE_MM: 'Donate Change Mm',
    DONATE_CHANGE_SUPPORT: 'Donate Change Support',
    DONATE_CHANGE_CTA: 'Donate Change Cta',
    DONATE_SETTINGS_SHOW_BRAND: 'Donate Settings Show Brand',
    DONATE_SETTINGS_ALT_TEXT: 'Donate Settings Alt Text',
    GOOGLE_AD_SETTINGS_ADID: 'Google Ad Settings Adid',
    GOOGLE_AD_SETTINGS_ADSLOT: 'Google Ad Settings Adslot',
    GOOGLE_AD_SETTINGS_BACKGROUND_LIGHT: 'Google Ad Settings Background Light',
    GOOGLE_AD_SETTINGS_BACKGROUND_DARK: 'Google Ad Settings Background Dark',
    GOOGLE_AD_SETTINGS_SHOWSUPPORTTEXT: 'Google Ad Settings Showsupporttext',
    GOOGLE_AD_SETTINGS_SHOWCTATEXT: 'Google Ad Settings Showctatext',
    GOOGLE_AD_SUPPORTTEXT: 'Google Ad Supporttext',
    GOOGLE_AD_CTATEXT: 'Google Ad Ctatext',
    SPONSOR_SETTINGS_ALIGNMENT: 'Sponsor Settings Alignment',
    IMAGE_SETTINGS_ALIGNMENT: 'Image Settings Alignment',
    SPONSOR_ADD_LOGO: 'Sponsor Add Logo',
    SPONSOR_DELETE_LOGO: 'Sponsor Delete Logo',
    SPONSOR_SORT_LOGO: 'Sponsor Sort Logo',
    SPONSOR_UPDATE_LOGO: 'Sponsor Update Logo',
    SPONSOR_SET_TITLE: 'Sponsor Set Title',
    SPONSOR_SET_DESCRIPTION: 'Sponsor Set Description',
    SPONSOR_SET_FUNDING: 'Sponsor Set Funding',
    SPONSOR_SET_SUPPORT: 'Sponsor Set Support',
    STATION_HEADER_SET_LOGO: 'Station Header Set Logo',
    STATION_HEADER_SET_LOGO_MM: 'Station Header Set Logo Mm',
    STATION_HEADER_SETTINGS_ISSTICKY: 'Station Header Settings Is Sticky',
    STATION_HEADER_SETTINGS_HASHIGHLIGHT: 'Station Header Settings Has Highlight',
    STATION_HEADER_SETTINGS_SHOWLOGO: 'Station Header Settings Show Logo',
    STATION_HEADER_SETTINGS_SHOWSEARCH: 'Station Header Settings Show Search',
    STATION_HEADER_SETTINGS_SHOWLIVE: 'Station Header Settings Show Live',
    STATION_HEADER_SETTINGS_LIVELINKURL: 'Station Header Settings Live Link URL',
    STATION_HEADER_SETTINGS_LOGOALTTEXT: 'Station Header Settings Logo Alt Text',
    STATION_HEADER_SETTINGS_SEARCHPLACEHOLDER: 'Station Header Settings Search Placeholder',
    MENU_COMMON_ADD_ITEM: 'Menu Common Add Item',
    MENU_COMMON_ADD_SUBITEM: 'Menu Common Add Subitem',
    MENU_COMMON_DELETE_ITEM: 'Menu Common Delete Item',
    MENU_COMMON_SET_ITEM: 'Menu Common Set Item',
    MENU_COMMON_SORT_ITEM: 'Menu Common Sort Item',
    MENU_COMMON_SORT_SUB_ITEMS: 'Menu Common Sort Sub Items',
    MENU_COMMON_CURRENT_INDEX: 'Menu Common Current Index',
    JAWS_COMPONENT_SETTINGS_API: 'Jaws Component Settings Api',
    FLEXIBLE_FOOTER_NEW_ITEM: 'Flexible Footer New Item',
    FLEXIBLE_FOOTER_CHANGE_ITEM: 'Flexible Footer Change Item',
    FLEXIBLE_FOOTER_DELETE_ITEM: 'Flexible Footer Delete Item',
    FLEXIBLE_FOOTER_SORT_ITEMS: 'Flexible Footer Sort Items',
    FLEXIBLE_FOOTER_SET_LOGO_IMG: 'Flexible Footer Set Logo Img',
    FLEXIBLE_FOOTER_SET_LOGO_LINK: 'Flexible Footer Set Logo Link',
    FLEXIBLE_FOOTER_SET_LOGO: 'Flexible Footer Set Logo',
    FLEXIBLE_FOOTER_SETTINGS_SHOWDETAILS: 'Flexible Footer Settings Show Details',
    FLEXIBLE_FOOTER_SETTINGS_SHOWSOCIAL: 'Flexible Footer Settings Show Social',
    FLEXIBLE_FOOTER_SETTINGS_SHOWCOPYRIGHT: 'Flexible Footer Settings Show Copyright',
    FLEXIBLE_FOOTER_SETTINGS_SHOWLOGO: 'Flexible Footer Settings Show Logo',
    FLEXIBLE_FOOTER_SETTINGS_SHOW_HEADER: 'Flexible Footer Settings Show Header',
    FLEXIBLE_FOOTER_SETTINGS_TYPE_COMPACT: 'Flexible Footer Settings Type Compact',
    FLEXIBLE_FOOTER_SETTINGS_TYPE_EXPANDED: 'Flexible Footer Settings Type Expanded',
    FLEXIBLE_FOOTER_SETTINGS_BACKGROUND_LIGHT: 'Flexible Footer Settings Background Light',
    FLEXIBLE_FOOTER_SETTINGS_BACKGROUND_DARK: 'Flexible Footer Settings Background Dark',
    FLEXIBLE_FOOTER_SETTINGS_LOGOALTTEXT: 'Flexible Footer Settings Logo Alt Text',
    EXPANDED_FOOTER_SET_DETAILS: 'Expanded Footer Set Details',
    EXPANDED_FOOTER_NEW_ITEM: 'Expanded Footer New Item',
    EXPANDED_FOOTER_CHANGE_LINK: 'Expanded Footer Change Link',
    EXPANDED_FOOTER_CHANGE_HEADER: 'Expanded Footer Change Header',
    EXPANDED_FOOTER_DELETE_ITEM: 'Expanded Footer Delete Item',
    EXPANDED_FOOTER_SORT_ITEMS: 'Expanded Footer Sort Items',
    COMPACT_FOOTER_ADD_ITEM: 'Compact Footer Add Item',
    COMPACT_FOOTER_CHANGE_ITEM: 'Compact Footer Change Item',
    COMPACT_FOOTER_DELETE_ITEM: 'Compact Footer Delete Item',
    COMPACT_FOOTER_SORT_ITEMS: 'Compact Footer Sort Items',
    BLOG_ENTRY_SETTINGS_NEW_TAG: 'Blog Entry Settings New Tag',
    BLOG_ENTRY_SETTINGS_TAGS: 'Blog Entry Settings Tags',
    BLOG_ENTRY_SETTINGS_TOOGLE: 'Blog Entry Settings Toogle',
    BLOG_ENTRY_ALT_TEXT: 'Blog Entry Alt Text',
    BLOG_ENTRY_IMAGE_DATA: 'Blog Entry Image Data',
    BLOG_ENTRY_IMAGE_SETTINGS: 'Blog Entry Image Settings',
    BLOG_ENTRY_PUBLICATION_DATE: 'Blog Entry Publication Date',
    BLOG_ENTRY_NEW_AUTHOR: 'Blog Entry New Author',
    BLOG_ENTRY_UPDATE_DATA: 'Blog Entry Update Data',
    BLOG_ENTRY_SELECT_AUTHOR: 'Blog Entry Select Author',
    BLOG_ENTRY_CONTENT: 'Blog Entry Content',
    MENU_COLUMN_CHANGE: 'Menu Column Change',
    MENU_PROMO_SET_IMG_LINK: 'Menu Promo Set Img Link',
    MENU_PROMO_SET_DETAILS: 'Menu Promo Set Details',
    MENU_PROMO_SET_DATA: 'Menu Promo Set Data',
    MENU_PROMO_NEW_IMG: 'Menu Promo New Img',
    MENU_PROMO_NEW_IMG_MM: 'Menu Promo New Img Mm',
    MENU_PROMO_TITLE: 'Menu Promo Title',
    MENU_SETTINGS_SET_ALIGNMENT: 'Menu Settings Alignment',
    MENU_SETTINGS_SET_DISPLAYTYPE: 'Menu Settings Display Type',
    MENU_PROMO_SETTINGS_SET_SHOWTITLE: 'Menu Promo Settings Set Show Title',
    MENU_PROMO_SETTINGS_SET_SHOWDETAILS: 'Menu Promo Settings Set Show Details',
    MENU_PROMO_SETTINGS_ALT_TEXT: 'Menu Promo Settings Alt Text',
    PAGE_HEADER_SETTINGS_BACKGROUND_COLOR: 'Page Header Settings Background Color',
    PAGE_HEADER_SETTINGS_TEXT_COLOR: 'Page Header Settings Text Color',
    PAGE_HEADER_SETTINGS_SHOWONHOME: 'Page Header Settings Show On Home',
    PAGE_HEADER_SETTINGS_SHOWPROGRAMLOGO: 'Page Header Settings Show Program Logo',
    PAGE_HEADER_SETTINGS_SHOWPAGETITLE: 'Page Header Settings Show Page Title',
    PAGE_HEADER_SETTINGS_SHOWSOCIALICONS: 'Page Header Settings Show Social Icons',
    PAGE_HEADER_SETTINGS_SHOWBACKGROUNDIMAGE: 'Page Header Settings Show Background Image',
    PAGE_HEADER_SETTINGS_TEXT: 'Page Header Settings Text',
    SECTION_HEADER_SETTINGS_RESET_COLORS: 'Section Header Settings Reset Colors',
    SECTION_HEADER_SETTINGS_SECONDARY_COLOR: 'Section Header Settings Secondary Color',
    SECTION_HEADER_SETTINGS_TEXT_COLOR: 'Section Header Settings Text Color',
    SECTION_HEADER_SETTINGS_TAG: 'Section Header Settings Tag',
    SECTION_HEADER_HEADING: 'Section Header Heading',
    PAGE_HEADER_PAGE_TITLE: 'Page Header Page Title',
    PAGE_HEADER_NEW_IMAGE: 'Page Header New Image',
    PAGE_HEADER_HEADER_MM: 'Page Header Header Mm',
    SECTION_HEADER_TEXT: 'Section Header Text',
    CALENDAR_CHANGE_TITLE: 'Calendar Change Title',
    CALENDAR_SETTINGS_CHANGE_ID: 'Calendar Settings Change Id',
    BENTOMATIC_SET_SOURCE: 'Bentomatic Set Source',
    BENTOMATIC_OVERRIDE_VARIABLE: 'Bentomatic Override Variable',
    BENTOMATIC_IMG_OVERRIDE_VARIABLE: 'Bentomatic Img Override Variable',
    GOOGLE_SEARCH_RESULTS_SETTINGS_CHANGE_ID: 'Google Search Results Settings Change Id',
    BLOG_RIVER_SETTINGS_CATEGORIES: 'Blog River Settings Categories',
    BLOG_RIVER_SETTINGS_ITEMS_PER_PAGE: 'Blog River Settings Items Per Page',
    BLOG_RIVER_SETTINGS_ORDER: 'Blog River Settings Order',
    BLOG_RIVER_SETTINGS_PAGINATION_TYPE: 'Blog River Settings Pagination Type',
    BLOG_RIVER_SETTINGS_SHOW_BLOG_RIVER_TITLE: 'Blog River Settings Show Blog River Title',
    BLOG_RIVER_SETTINGS_SHOW_IMAGE: 'Blog River Settings Show Image',
    BLOG_RIVER_SETTINGS_SHOW_POST_DATE: 'Blog River Settings Show Post Date',
    BLOG_RIVER_SETTINGS_SHOW_AUTHOR: 'Blog River Settings Show Author',
    BLOG_RIVER_SETTINGS_SHOW_CALL_TO_ACTION: 'Blog River Settings Show Call To Action',
    BLOG_RIVER_SETTINGS_TAGS: 'Blog River Settings Tags',
    BLOG_RIVER_SETTINGS_FEATURED: 'Blog River Settings Featured',
    CAROUSEL_SETTINGS_CHANGE_TYPE_CIRCLE_CROP: 'Carousel Settings Change Type Circle Crop',
    CAROUSEL_SETTINGS_CHANGE_TYPE_FULL_SIZE: 'Carousel Settings Change Type Full Size',
    CAROUSEL_SETTINGS_CHANGE_TYPE_REGULAR: 'Carousel Settings Change Type Regular',
    CAROUSEL_SETTINGS_BACKGROUND_STYLE: 'Carousel Settings Background Style',
    CAROUSEL_SLIDE_DETAILS_STYLE: 'Carousel Slide Details Style',
    CUSTOM_PROMO_SETTINGS_ORIENTATION: 'Custom Promo Settings Orientation',
    CUSTOM_PROMO_SETTINGS_ALT_TEXT: 'Custom Promo Settings Alt Text',
    GALLERY_IMAGE_SETTINGS_ALT_TEXT: 'Gallery Image Settings Alt Text',
    LINK_SETTINGS_SHOW_TITLE: 'Link Settings Show Title',
    POSTER_GRID_SETTINGS_PAGINATION: 'Poster Grid Settings Pagination',
    POSTER_GRID_SETTINGS_ITEMS_PER_PAGE: 'Poster Grid Settings Items Per Page',
    POSTER_SETTINGS_ALT_TEXT: 'Poster Settings Alt Text',
    PROFILE_GRID_SETTINGS_PAGINATION: 'Profile Grid Settings Pagination',
    PROFILE_SETTINGS_SHOW_CTA: 'Profile Settings Show CTA',
    RSS_FEED_SETTINGS_SET_URL: 'Rss Feed Settings Set Url',
    RSS_FEED_SETTINGS_ITEMS_PER_PAGE: 'Rss Feed Settings Items Per Page',
    SIMPLE_BUTTON_SETTINGS_COLOR: 'Simple Button Settings Color',
    SIMPLE_BUTTON_SETTINGS_SHOW_HOVER: 'Simple Button Settings Show Hover',
    SIMPLE_BUTTON_SETTINGS_COLOR_RESET: 'Simple Button Settings Color Reset',
    LOGO_SETTINGS_ALT_TEXT: 'Logo Settings Alt Text',
    LOGO_SETTINGS_CHANGE_IMAGE: 'Logo Settings Image Url',
    VIDEO_LIST_SETTINGS_PLAYER_CONFIG_TOPBAR: 'Video List Settings Player Config Topbar',
    VIDEO_LIST_SETTINGS_PLAYER_CONFIG_AUTOPLAY: 'Video List Settings Player Config Autoplay',
    SIMPLE_BUTTON_SETTINGS_STYLE: 'Simple Button Settings Style',
    SIMPLE_BUTTON_SETTINGS_SIZE: 'Simple Button Settings Size',
    SIMPLE_BUTTON_SETTINGS_ALIGNMENT: 'Simple Button Settings Alignment',
    POPUP_SETTINGS_COLOR_THEME: 'Popup Settings Color Theme',
    POPUP_SETTINGS_SIZE: 'Popup Settings Size',
    POPUP_SETTINGS_TRIGGER_TYPE: 'Popup Settings Trigger Type',
    POPUP_SETTINGS_ON_PAGES: 'Popup Settings On Pages',
    MENU_SETTINGS_SET_TYPE: 'Menu Settings Set Type',
    IMAGE_GALLERY_CHANGE_IMAGE: 'Image Gallery Change Image',
    IMAGE_GALLERY_IMAGE_DESCRIPTION: 'Image Gallery Image Description',
    IMAGE_GALLERY_IMAGE_CREDIT: 'Image Gallery Image Credit',
    IMAGE_GALLERY_REMOVE_IMAGE: 'Image Gallery Remove Image',
    IMAGE_GALLERY_ADD_IMAGE: 'Image Gallery Add Image',
    IMAGE_GALLERY_DATA: 'Image Gallery Data',
    IMAGE_GALLERY_SET_IMAGE: 'Image Gallery Set Image',
    IMAGE_GALLERY_TITLE: 'Image Gallery Title',
    IMAGE_GALLERY_SORT_IMAGES: 'Image Gallery Sort Images',
    POSTER_GRID_TITLE: 'Poster Grid Title',
    POSTER_GRID_DESCRIPTION: 'Poster Grid Description',
    POSTER_GRID_ADD_POSTER: 'Poster Grid Add Poster',
    POSTER_GRID_DELETE_POSTER: 'Poster Grid Delete Poster',
    POSTER_GRID_SORT_POSTERS: 'Poster Grid Sort Posters',
    POSTER_GRID_IMAGE_ADD_CTA: 'Poster Grid Image Add CTA',
    POSTER_GRID_IMAGE_CHANGE_CTA: 'Poster Grid Image Change CTA',
    POSTER_GRID_IMAGE_DELETE_CTA: 'Poster Grid Image Delete CTA',
    POSTER_GRID_IMAGE_SORT_CTA: 'Poster Grid Image Sort CTA',
    POSTER_GRID_IMAGE_LINK: 'Poster Grid Image Link',
    POSTER_GRID_IMAGE_CHANGE_IMAGE: 'Poster Grid Image Change Image',
    PROFILE_GRID_ADD_PROFILE: 'Profile Grid Add Profile',
    PROFILE_GRID_DELETE_PROFILE: 'Profile Grid Delete Profile',
    PROFILE_GRID_SORT_PROFILES: 'Profile Grid Sort Profiles',
    PROFILE_GRID_PROFILE_CHANGE_RELATED_LINK: 'Profile Grid Profile Change Related Link',
    PROFILE_GRID_PROFILE_ADD_RELATED_LINK: 'Profile Grid Profile Add Related Link',
    PROFILE_GRID_PROFILE_DELETE_RELATED_LINK: 'Profile Grid Profile Delete Related Link',
    PROFILE_GRID_PROFILE_CHANGE_GRID_IMAGE: 'Profile Grid Profile Change Image',
    PROFILE_GRID_PROFILE_CHANGE_IMAGE: 'Profile Grid Profile Change Image',
    PROFILE_GRID_PROFILE_CREDIT_TEXT: 'Profile Grid Profile Credit Text',
    PROFILE_GRID_PROFILE_TITLE: 'Profile Grid Profile Title',
    PROFILE_GRID_PROFILE_DESCRIPTION: 'Profile Grid Profile Description',
    PROFILE_GRID_PROFILE_CTA_TEXT: 'Profile Grid Profile CTA Text',
    PROFILE_GRID_PROFILE_CTA_LINK: 'Profile Grid Profile CTA Link',
    PROFILE_GRID_PROFILE_LINKS_TITLE: 'Profile Grid Profile Links Title',
    CAROUSEL_ADD_SLIDE: 'Carousel Add Slide',
    CAROUSEL_SORT_SLIDES: 'Carousel Sort Slides',
    CAROUSEL_SLIDE_CHANGE_IMAGE: 'Carousel Slide Change Image',
    CUSTOM_PROMO_CTA_LINK: 'Custom Promo CTA Link',
    CUSTOM_PROMO_IMAGE_LINK: 'Custom Promo Image Link',
    CUSTOM_PROMO_CHANGE_IMAGE: 'Custom Promo Change Image',
    POSTER_GRID_IMAGE_TITLE: 'Poster Grid Image Title',
    POSTER_GRID_IMAGE_DESCRIPTION: 'Poster Grid Image Description',
    BLOG_RIVER_TITLE: 'Blog River Title'
};

export const statusCodes = {
    STATUS_CODE_STR_301: '301',
    STATUS_CODE_INT_301: 301,
    STATUS_CODE_STR_302: '302',
    STATUS_CODE_INT_302: 302
};

export const REGIONS = [
    { value: 'US', label: 'US' },
    { value: 'PR', label: 'US & Territories' },
    { value: 'MX', label: 'Mexico' },
    { value: 'CA', label: 'Canada' }
];

export const PBSTheme = {
    PBS_COLOR_THEME_ID: 76,
    PBS_COLOR_THEME_NAME: 'PBS'
};

export const SURVEY_HOLIDAY_TYPE = 'survey';
